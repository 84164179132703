import { useState } from 'react'
import { Link } from 'react-router-dom'
import AnimatedLetters from '../AnimatedLetters'
import Logo from './Logo'
import './index.scss'
import Loader from 'react-loaders'

const Home = () => {
    const [letterClass] = useState('text-animate')
    const nameArray = ['M', 'A', 'X', 'I', 'S', 'Y', ',']
    const jobArray = ['a', ' ', 'S', 'o', 'l', 'a', 'n', 'a', ' ', 'B', 'l', 'o', 'c', 'k', 'c', 'h', 'a', 'i', 'n', ' ', 'D', 'e', 'v', 'e', 'l', 'o', 'p', 'e', 'r']

    return (
        <>
            <div className="container home-page">
                <div className="text-zone">
                    <h1>
                        <span className={letterClass}>H</span>
                        <span className={`${letterClass} _12`}>i,</span>
                        <br />
                        <span className={`${letterClass} _13`}>I</span>
                        <span className={`${letterClass} _14`}>'m</span>
                        &nbsp;
                        <AnimatedLetters letterClass={letterClass} strArray={nameArray} idx={15} />
                        <br />
                        <AnimatedLetters letterClass={letterClass} strArray={jobArray} idx={22} />
                    </h1>
                    <h2>Solana token & NFT airdrops, snapshots, Discord bots and everything else you need.</h2>
                    <Link to="/contact" className='flat-button'>CONTACT ME</Link>
                </div>
                <Logo />
            </div>
            <Loader type="ball-grid-beat" />
        </>
    )
}

export default Home