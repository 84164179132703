import { faCss3, faGitAlt, faHtml5, faJava, faPython, faReact } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters'
import './index.scss'

const About = () => {
    const letterClass = useState('text-animate')

    return (
        <>
            <div className='container about-page'>
                <div className='text-zone'>
                    <h1>
                        <AnimatedLetters 
                            letterClass={letterClass}
                            strArray={['A', 'b', 'o', 'u', 't', ' ', 'm', 'e']}
                            idx={15}
                        />
                    </h1>
                    <p>
                        I'm a Solana Blockchain Developer looking forward to helping Solana NFT projects
                        in developing their utility.
                    </p>
                    <p>
                        I can create a custom program to fit your needs, handle SOL & NFT airdrops, snapshots,
                        creating metadata, generating NFTs from layers and minting them on the Solana blockchain
                        or creating an NFT launch page.
                    </p>
                    <p>
                        I can also create custom Discord bots for your server which could help you with managing
                        your community.
                    </p>
                </div>

                <div className='stage-cube-cont'>
                    <div className='cubespinner'>
                        <div className='face1'>
                            <FontAwesomeIcon icon={faPython} color="#4B8BBE" />
                        </div>
                        <div className='face2'>
                            <FontAwesomeIcon icon={faHtml5} color="#F06529" />
                        </div>
                        <div className='face3'>
                            <FontAwesomeIcon icon={faJava} color="#f89820"/>
                        </div>
                        <div className='face4'>
                            <FontAwesomeIcon icon={faGitAlt} color="#ec4d28" />
                        </div>
                        <div className='face5'>
                            <FontAwesomeIcon  icon={faCss3} color="#28A4D9" />
                        </div>
                        <div className='face6'>
                            <FontAwesomeIcon icon={faReact} color="#5Ed4F4" />
                        </div>
                    </div>
                </div>
            </div>
            <Loader type="ball-grid-beat" />
        </>
    )
}

export default About