import { useRef, useState } from 'react'
import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters'
import emailjs from '@emailjs/browser'
import CodeImage from '../../assets/images/code_image.png'
import './index.scss'

const Contact = () => {
    const letterClass = useState('text-animate')
    const refForm = useRef()

    const sendEmail = (e) => {
        e.preventDefault()

        emailjs.sendForm(
            'service_cx8y3sk',
            'template_f1iitmo',
            refForm.current,
            'TEyBzHa-kdUpdeyv9'
        )
        .then (
            () => {
                alert('Message sent!')
                window.location.reload(false)
            },
            () => {
                alert('Failed to send the message, please try again')
            }
        )
    }

    return (
        <>
            <div className='container contact-page'>
                <div className='text-zone'>
                    <h1>
                        <AnimatedLetters letterClass={letterClass} strArray={['C', 'o', 'n', 't', 'a', 'c', 't', ' ', 'M', 'e']} idx={15} />
                    </h1>
                    <p>
                        I am interested in freelance jobs for Solana blockchain projects,
                        but if you have any other job oppoturnities or questions, feel free
                        to contact me.
                    </p>
                    <div className='contact-form'>
                        <form ref={refForm} onSubmit={sendEmail}>
                            <ul>
                                <li className='half'>
                                    <input type="text" name="name" placeholder="Name" required />
                                </li>
                                <li className='half'>
                                    <input type="email" name="email" placeholder="Email" required />
                                </li>
                                <li>
                                    <input placeholder="Subject" type="text" name="subject" requirde />
                                </li>
                                <li>
                                    <textarea placeholder='Message' name="message" required />
                                </li>
                                <li>
                                    <input type="submit" className='flat-button' value="Send" />
                                </li>
                            </ul>
                        </form>
                    </div>
                </div>
            </div>
            <Loader type="ball-grid-beat" />
        </>
    )
}

export default Contact