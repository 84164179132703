import './index.scss'
import LogoS from '../../../assets/images/avatar_m_hq.jpg'

const Logo = () => {
    return (
        <div className='logo-container'>
            <img className='solid-logo' src={LogoS} alt="m" />
        </div>
    )
}

export default Logo