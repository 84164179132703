import { useState } from 'react'
import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters'
import './index.scss'

const Experience = () => {
    const letterClass = useState('text-animate')

    return (
        <>
            <div className='container experience-page'>
                <div className='text-zone'>
                    <h1>
                        <AnimatedLetters 
                            letterClass={letterClass}
                            strArray={['M', 'y', ' ', 'E', 'x', 'p', 'e', 'r', 'i', 'e', 'n', 'c', 'e']}
                            idx={15}
                        />
                    </h1>
                    <p>
                        I am a Java Developer since 2017 and I know Python since 2020.
                        I started exploring Blockchain Development in year 2021 and I have learned a lot about it since then.
                        Thanks to that, I am able to create applications interacting with the Solana blockchain.
                    </p>
                    <p>
                        I am working/have worked for multiple Solana NFT projects, including:
                        <ul>
                            <li><a href="https://twitter.com/AlphaPharaohs" target="_blank" rel="noreferrer">Alpha Pharaohs</a></li>
                            <li><a href="https://twitter.com/ClarityDAO" target="_blank" rel="noreferrer">Clarity DAO</a></li>
                            <li><a href="https://twitter.com/blxckout_nft" target="_blank" rel="noreferrer">BLXCKOUT Project</a></li>
                            <li><a href="https://twitter.com/MythicalTownNFT" target="_blank" rel="noreferrer">Mythical Town</a></li>
                            <li><a href="https://twitter.com/SOL_Scripts" target="_blank" rel="noreferrer">SOL Revenue Share</a></li>
                        </ul>
                        and more.
                    </p>
                    <p>
                        My recent projects include a Twitter Raid2Earn bot, Magic Eden Sniper, Wallet Submission bot, Airdrop & Snapshot Scripts and more.
                    </p>
                </div>

                <div className='stage-cube-cont'>
                    
                </div>
            </div>
            <Loader type="ball-grid-beat" />
        </>
    )
}

export default Experience